import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/OrderType/style';
import { Box, Link, Typography } from '@mui/material';
import { CheckImg, oneHourDeliveryCar } from 'utils/cloudinaryAssets';
import { DELIVERY_POLICY } from 'containers/MainContainer/constants/path';
import { Image } from 'components/common/Image/Image';
import { Order } from 'redux/order-confirmation/types/types';
import { formatToReadableDateWithoutTime } from 'utils/date';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  order: Order;
}

export const Delivery: FC<Props> = ({ order }) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={styles.wrapper}
    key={order.orderID}
  >
    <Typography sx={styles.title}>
      {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_TITLE)}
    </Typography>
    <Box mb={[3, 3, 0]} className="rightStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_METHOD_NAME)}
      </Typography>
      {order.deliveryInstruction.includes('One Hour Delivery Order') ? (
        <Box sx={styles.OneHrDeliveryWrapper}>
          <Box sx={styles.oneHrDeliveryImage}>
            <Image src={oneHourDeliveryCar} />
          </Box>
          <Typography sx={styles.oneDeliveryText} variant="subtitle3">
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_ONE_HOUR_DELIVERY_METHOD)}
          </Typography>
        </Box>
      ) : (
        <Typography sx={styles.infoText}>
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_METHOD)}
        </Typography>
      )}
    </Box>
    <Box mb={[3, 3, 0]} className="leftStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_ON)}
      </Typography>
      <Typography sx={styles.infoText}>
        {formatToReadableDateWithoutTime(
          new Date(order.fulfillmentDate.toString().replace('Z', '')),
        )}
      </Typography>
    </Box>
    <Box mb={[3, 3, 0]} className="rightStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_INSTRUCTION)}
      </Typography>
      <Typography sx={styles.infoText}>
        {order.deliveryInstruction !== ''
          ? order.deliveryInstruction
          : i18next.t(TK.ORDER_CONFIRMATION_PAGE_NO_INSTRUCTIONS)}
      </Typography>
    </Box>
    <Box mb={[3, 3, 0]} className="rightStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_TO)}
      </Typography>
      <Typography sx={styles.infoText}>{order.recipientName}</Typography>
      <Typography sx={styles.infoText} style={{ marginTop: '5px' }}>
        {order.address1}
      </Typography>
      <Typography sx={styles.infoText} style={{ marginTop: '5px' }}>
        {order.city}, {order.stateAbbreviation} {order.area}
      </Typography>
      <Typography sx={styles.infoText}>{order.workPhone}</Typography>
      <Typography sx={styles.infoText}>{order.homePhone}</Typography>
      <Typography sx={styles.infoText}>{order.cellPhone}</Typography>
    </Box>
    <Box mb={[3, 3, 0]} className="leftStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_FROM)}
      </Typography>
      <Typography sx={styles.infoText}>{order.formalName}</Typography>
      <Typography sx={styles.infoText} style={{ marginTop: '5px' }}>
        {order.storeAddress1}
      </Typography>
      <Typography sx={styles.infoText} style={{ marginTop: '5px' }}>
        {order.storeCity}, {order.stateAbbreviation} {order.storeArea}
      </Typography>
      <Typography sx={styles.infoText}>{order.storePhone}</Typography>
    </Box>
    <Box className="rightStyle" mb={[0, 4, 0]}>
      <img
        alt="icon"
        width={20}
        height={20}
        className="checkImg"
        src={CheckImg}
      />
      <Link href={DELIVERY_POLICY} sx={styles.deliveryLink}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_POLICY)}
      </Link>
    </Box>
  </Box>
);
