export const WHERE_IS_IT_GOING = 'pdp.availability.where_is_it_going';
export const ZIP_LABEL = 'pdp.availability.zip_label';
export const POSTAL_CODE_LABEL = 'pdp.availability.postal_code_label';
export const ZIP_SERVICE_WARNING =
  'pdp.availability.zip_unfortunately_does_not_service';
export const ZIP_VALIDATE_ERROR = 'pdp.availability.zip_validate_error';
export const POSTAL_CODE_ERROR = 'pdp.availability.postal_code_validate_error';
export const ZIP_USA_ERROR = 'pdp.availability.zip_usa_error';
export const ZIP_USA_ERROR_SUB = 'pdp.availability.zip_usa_error_sub';
export const SHOP_EDIBLE_US = 'pdp.availability.shop_edible_us';
