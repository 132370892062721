import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as TK from 'translations/locales/translation-keys.constant';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  CarouselContainer,
  CarouselWrapper,
  Headline,
  arrowControls,
  title,
} from 'components/OrderConfirmation/TopSeller/styles';
import { CarouselControl } from 'components/Home/common/CarouselControl';
import { CarouselDetailedItem } from 'components/OrderConfirmation/TopSeller/type';
import { CarouselItem } from 'components/OrderConfirmation/TopSeller/components/CarouselItem/index';
import { PLPArrangementType } from 'components/common/ArrangementsGrid/components/plp-arrangement-type';
import { PureHtmlTypography } from 'components/common/PureHtml/PureHtmlTypography';
import {
  ViewAllButtonDesktop,
  ViewAllButtonMobile,
} from 'components/Home/common/styled';
import { analytics } from 'service/adobe-analytics/analytics';
import { isDefined } from 'utils/is-defined';
import { selectArrangementInactive } from 'components/common/ArrangementGroup/feature/selectors';
import { selectArrangements } from 'components/PLP/ArrangementsList/feature/selectors';
import { settings } from 'components/OrderConfirmation/TopSeller/settings';
import { useSelector } from 'react-redux';
import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

export const CarouselDetailed: FC = () => {
  const arrangements = useSelector(selectArrangements);
  const slicedArray = arrangements
    .filter((x) => x.plpItemType === PLPArrangementType.arrangement)
    .slice(0, 10);
  const isInactive = useSelector(selectArrangementInactive);
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(
    null,
  );
  const user = useSelector(({ userSession: { data } }) => data);

  if (slicedArray.length === 0 || isInactive) {
    return null;
  }

  const onRef = (divElement: HTMLDivElement) => {
    if (isDefined(divElement)) {
      setPortalContainer(divElement);
    }
  };

  return (
    <CarouselWrapper data-test="carousel-detailed-container">
      <hr className="lineStyle" />
      <Headline>
        <PureHtmlTypography
          sx={title}
          variant="h1"
          data-test="carousel-detailed-title"
        >
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_KEEP_SHOPPING_TITLE)}
        </PureHtmlTypography>
        <ViewAllButtonDesktop
          size="large"
          href="/"
          variant="outlined"
          color="primary"
          data-test="view-all-button-big"
          onClick={() => {
            analytics.startNewOrderClicked(user?.userId > 0 ? user?.userId : 0);
          }}
        >
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_NEW_ORDER)}
        </ViewAllButtonDesktop>
        <Box ref={onRef} sx={arrowControls} />
      </Headline>
      <CarouselContainer data-test="carousel-detailed">
        <Slider
          {...settings}
          nextArrow={
            <CarouselControl portalContainerEl={portalContainer}>
              <ArrowForwardIos fontSize="small" />
            </CarouselControl>
          }
          prevArrow={
            <CarouselControl portalContainerEl={portalContainer}>
              <ArrowBackIosNew fontSize="small" />
            </CarouselControl>
          }
        >
          {slicedArray.map((carouselItem: CarouselDetailedItem) => (
            <CarouselItem
              key={carouselItem.id}
              item={carouselItem}
              data-test="carousel-detailed-slide"
            />
          ))}
        </Slider>
      </CarouselContainer>
      <ViewAllButtonMobile
        size="large"
        variant="outlined"
        href="/top-sellers"
        color="primary"
        data-test="view-all-button-big"
        style={{ marginBottom: '32px' }}
        onClick={() => {
          analytics.startNewOrderClicked(user?.userId > 0 ? user?.userId : 0);
        }}
      >
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_NEW_ORDER)}
      </ViewAllButtonMobile>
      <hr className="lineStyle" />
    </CarouselWrapper>
  );
};
