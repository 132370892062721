export const HOME = 'customer_service_page.edible_home_page';
export const CUSTOMER_SERVICE_PAGE =
  'customer_service_page.edible_customer_service_page';
export const CUSTOMER_SERVICE_META_TITLE_TAG =
  'customer_service_page.edible_meta_title_tag';
export const CUSTOMER_SERVICE_FAQ_PAGE = 'customer_service_page.faq_page';

export const trackOrder = {
  TITLE: 'customer_service_page.track_order_title',
  HELPER_TEXT: 'customer_service_page.track_order_helper_text',
  ORDER_NUMBER_LABEL_TEXT:
    'customer_service_page.track_order_order_number_label_text',
  BTN_TEXT: 'customer_service_page.track_order_btn_text',
  ORDER_NUMBER_VALID_ERROR:
    'customer_service_page.track_order_order_number_valid_error',
  ORDER_NUMBER_REQUIRED_ERROR:
    'customer_service_page.track_order_order_number_required_error',
  ORDER_NUMBER_OVER_ERROR:
    'customer_service_page.track_order_order_number_over_error',
  ORDER_NUMBER_UNDER_ERROR:
    'customer_service_page.track_order_order_number_under_error',
  ORDER_NOT_FOUND_TITLE:
    'customer_service_page.track_order_order_not_found_title_error',
  ORDER_NOT_FOUND_ERROR:
    'customer_service_page.track_order_order_not_found_error_message',
  CUSTOMER_SERVICE: 'customer_service_page.track_order_customer_service',
  TOOLTIP_TEXT: 'customer_service_page.track_order_tooltip_text',
  EXAMPLE_ORDER_NUMBER:
    'customer_service_page.track_order_example_order_number',
};

export const tracking = {
  TITLE: 'customer_service_page.tracking_status_title',
  NUM: 'customer_service_page.tracking_status_helper_text',
  ORDER_PLACED: 'customer_service_page.tracking_status_order_placed_title',
  RECEIVED_BY_STORE:
    'customer_service_page.tracking_status_received_by_store_title',
  CRAFTING_ORDER: 'customer_service_page.tracking_status_crafting_order_title',
  OUT_FOR_DELIVERY:
    'customer_service_page.tracking_status_out_for_delivery_title',
  DELIVERED: 'customer_service_page.tracking_status_delivered_title',
  ATTEMPTED_DELIVERY:
    'customer_service_page.tracking_status_attempted_delivery_title',
  READY_FOR_PICKUP:
    'customer_service_page.tracking_status_ready_for_pickup_title',
  PICKED_UP: 'customer_service_page.tracking_status_order_picked_up_title',
  SHIPPED: 'customer_service_page.tracking_status_order_shipped_title',
  CANCELED: 'customer_service_page.tracking_status_canceled_title',
  CANCELED_SUBTITLE: 'customer_service_page.tracking_status_canceled_subtitle',
  CALL_STORE: 'customer_service_page.tracking_status_call_store',
  CUSTOMER_SUPPORT: 'customer_service_page.tracking_status_customer_support',
  CANCEL_ORDER: 'customer_service_page.tracking_status_cancel_order',
  ORDER_INQUIRY: 'customer_service_page.tracking_status_order_inquiry',
  TRACK_ORDER: 'customer_service_page.tracking_status_track_order',
  TRACK_ORDER_ROUTE_INFO:
    'customer_service_page.tracking_status_track_order_route_info',
  TRACK_ORDER_ROUTE_INFO_MULTI_STOP:
    'customer_service_page.tracking_status_track_order_route_info_multi_stop',
  TRACK_ORDER_ROUTE_INFO_NEXT_STOP:
    'customer_service_page.tracking_status_track_order_route_info_next_stop',
};

export const sale = {
  TITLE: 'customer_service_page.sale_title',
  ITEM_COUNT: 'customer_service_page.sale_item_count',
  QTY: 'customer_service_page.sale_quantity',
  ADDON_QTY: 'customer_service_page.sale_addon_quantity',
  PRICE: 'customer_service_page.sale_price',
  SUMMARY: 'customer_service_page.sale_summary',
  DELIVERY_FEE: 'customer_service_page.sale_delivery_fee',
  SERVICE_FEE: 'customer_service_page.sale_service_fee',
  SALES_TAX: 'customer_service_page.sale_sales_tax',
  SUBTOTAL: 'customer_service_page.sale_subtotal',
  TOTAL: 'customer_service_page.sale_total',
  MADE_IT_SPECIAL: 'customer_service_page.sale_made_it_special',
  SELECTED_UPGRADES: 'customer_service_page.sale_selected_upgrades',
  BUNDLE_SPECIAL_TREATS: 'customer_service_page.sale_bundle_special_treats',
  CYOA_TITLE1: 'customer_service_page.cyo_title1',
  CYOA_TITLE2: 'customer_service_page.cyo_title2',
  CYODB_TITLE: 'customer_service_page.cyodb_title',
  CYODB_NAME: 'customer_service_page.cyodb_name',
  CYO_6COOKIEBOX_TITLE: 'customer_service_page.cyo6cookiebox_title',
  CYO_6COOKIEBOX_NAME: 'customer_service_page.cyo6cookiebox_name',
  CYO_12COOKIEBOX_TITLE: 'customer_service_page.cyo12ookiebox_title',
  CYO_12COOKIEBOX_NAME: 'customer_service_page.cyo12cookiebox_name',
  CYO_DESSERT_NAME: 'customer_service_page.cyodessert_name',
  CYO_DIPPEDFRUIT_NAME: 'customer_service_page.cyodippedfruit_name',
  FREE_TEXT: 'customer_service_page.free_text',
  COUPON_CODE: 'customer_service_page.coupon_code',
};

export const certifiedHappinessPage = {
  TITLE: 'customer_service_page.certified_happiness_page_title',
  DESCRIPTION: 'customer_service_page.certified_happiness_page_description',
  POLICY1: 'customer_service_page.certified_happiness_page_policy1',
  POLICY2: 'customer_service_page.certified_happiness_page_policy2',
};

export const edibleGuaranteePage = {
  TITLE: 'customer_service_page.edible_guarantee_page_title',
  DESCRIPTION: 'customer_service_page.edible_guarantee_page_description',
  POLICY: 'customer_service_page.edible_guarantee_page_policy',
  HASSLEFREE: 'customer_service_page.edible_guarantee_page_hasslefree',
  SATISFACTION_HEADER:
    'customer_service_page.edible_guarantee_page_satisfaction_header',
  SUPPORT_HEADER: 'customer_service_page.edible_guarantee_page_support_header',
  STREAMLINED_HEADER:
    'customer_service_page.edible_guarantee_page_streamlined_header',
  SATISFACTION: 'customer_service_page.edible_guarantee_page_satisfaction',
  SUPPORT: 'customer_service_page.edible_guarantee_page_support',
  STREAMLINED: 'customer_service_page.edible_guarantee_page_streamlined',
};

export const allergySafetyInformationPage = {
  TITLE: 'customer_service_page.allergy_safety_information_title',
  DESCRIPTION1: 'customer_service_page.allergy_safety_information_description1',
  DESCRIPTION2: 'customer_service_page.allergy_safety_information_description2',
  DESCRIPTION3: 'customer_service_page.allergy_safety_information_description3',
  SUBTITLE_ONE: 'customer_service_page.allergy_safety_information.subtitle_one',
  SUBDESCRIPTION_ONE:
    'customer_service_page.allergy_safety_information.subdescription_one',
  SUBTITLE_TWO: 'customer_service_page.allergy_safety_information.subtitle_two',
  SUBDESCRIPTION_TWO:
    'customer_service_page.allergy_safety_information.subdescription_two',
  SUBTITLE_THREE:
    'customer_service_page.allergy_safety_information.subtitle_three',
  SUBDESCRIPTION_THREE:
    'customer_service_page.allergy_safety_information.subdescription_three',
  SUBTITLE_FOUR:
    'customer_service_page.allergy_safety_information.subtitle_four',
  SUBDESCRIPTION_FOUR:
    'customer_service_page.allergy_safety_information.subdescription_four',
  SUBTITLE_FIVE:
    'customer_service_page.allergy_safety_information.subtitle_five',
  SUBDESCRIPTION_FIVE:
    'customer_service_page.allergy_safety_information.subdescription_five',
  SUBTITLE_SIX: 'customer_service_page.allergy_safety_information.subtitle_six',
  SUBDESCRIPTION_SIX:
    'customer_service_page.allergy_safety_information.subdescription_six',
  SUBTITLE_SEVEN:
    'customer_service_page.allergy_safety_information.subtitle_seven',
  SUBDESCRIPTION_SEVEN:
    'customer_service_page.allergy_safety_information.subdescription_seven',
  COOKIES_PLEASE:
    'customer_service_page.allergy_safety_information.cookies_please',
  COOKIES_CLICK_HERE:
    'customer_service_page.allergy_safety_information.cookies_click_here',
};
