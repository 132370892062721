import * as TK from 'components/PDP/Details/components/PasControl/components/ZipAvailability/translations/locale.constants';

export const availabilityCommonLocale = {
  [TK.WHERE_IS_IT_GOING]: 'Where is this treat going?',
  [TK.POSTAL_CODE_LABEL]: 'Enter Postal Code',
  [TK.ZIP_LABEL]: 'Enter ZIP Code',
  [TK.POSTAL_CODE_ERROR]: 'Please enter a valid Postal Code',
  [TK.ZIP_VALIDATE_ERROR]: 'Please enter a valid ZIP code',
  [TK.ZIP_SERVICE_WARNING]:
    'Sorry! This product isn’t available in the area you selected.',
  [TK.ZIP_USA_ERROR]: 'Postal code not found in Canada.',
  [TK.ZIP_USA_ERROR_SUB]: 'Did you mean to enter a US zip code?',
  [TK.SHOP_EDIBLE_US]: 'Shop Edible US',
};
