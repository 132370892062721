import * as TK from 'components/PDP/Details/components/PasControl/translations/constants';
import { Area } from 'api/area/types/area.interface';
import { AvailabilityOnDate } from 'api/availability-on-date/types/availability-on-date.interface';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import { CollectionPrompt } from 'components/PDP/Details/components/PasControl/components/CollectionPrompt/CollectionPrompt';
import { DateSelection } from 'components/PDP/Details/components/PasControl/components/DateSelection/DateSelection';
import { DeliveryMethods } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryMethods';
import { Divider, Stepper, Typography } from '@mui/material';
import { Element } from 'react-scroll';
import { MarginWrapper } from 'components/common/MarginWrapper/styles';
import { OrderType, ValidOrderTypes } from 'types/cart.types';

import { OwnBoxAddon } from 'redux/PDP/arrangement/types/own-box.types';
import { Preview } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/Preview/Preview';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import {
  Step,
  StepContent,
} from 'components/PDP/Details/components/PasControl/components/Stepper/styled';
import { StepSummary } from 'components/PDP/Details/components/PasControl/components/Stepper/StepSummary';
import { Store } from 'redux/types/store.types';
import { StoreSelector } from 'components/PDP/Details/components/PasControl/components/StoreSelector/StoreSelector';
import { SuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/SuggestedArrangements';
import { SuggestedDates } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/SuggestedDates';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { ZipAvailability } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/ZipAvailability';
import { appInsights } from 'utils/telemetry/app-insights';
import {
  dateStep,
  notSelectedStep,
  orderTypeStep,
  steps,
  zipStep,
} from 'components/PDP/Details/components/PasControl/contants/steps';
import { formatSQLDate } from 'utils/get-sql-date-format';
import { getAreaAvailability } from 'redux/PDP/area/selectors';
import { getAvailabilityByType } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/utils/get-availability-by-type';
import { getCartCount } from 'components/Cart/feature/actions';
import { isAvailabilityStepGreaterThan } from 'containers/PDPContainer/pdp-container.utils';
import { isDelivery, isFulfillType } from 'utils/is-order-type';
import { isNotNull } from 'utils/null.utils';
import { isPositive } from 'utils/numbers';
import { isValidateDessertBoardItems } from 'components/PDP/Details/components/OwnBox/utils/is-validated';
import { pas } from 'containers/PDPContainer/feature/pas/slice';
import { reset as resetSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/SuggestedArrangement/feature/suggested-arrangements.slice';
import { segment } from 'service/segment/segment';
import { selectCatalogNumber } from 'redux/PDP/arrangement/arrangement.selectors';
import {
  selectDateAvailabilityData,
  selectIsNotAvailableAndFulfilled,
} from 'components/PDP/Details/components/PasControl/components/DateSelection/feature/selectors';
import {
  selectIsLockExist,
  selectIsOrderTypeLock,
} from 'redux/session/availability/availability.selectors';
import { selectRecipientId } from 'components/Session/feature/selectors';
import {
  selectRegion,
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import {
  shouldShowCalendar,
  shouldShowFlexCalendar,
} from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-dates';
import { shouldShowSuggestedArrangements } from 'components/PDP/Details/components/PasControl/components/DeliveryMethods/DeliveryOption/utils/should-show-suggested-arrangements';
import { updateAvailabilitySession } from 'redux/session/availability/availability.action';
import { useAutoSelect } from 'components/PDP/Details/components/PasControl/components/StoreSelector/hooks/use-autoselect';
import { useAvailabilityStep } from 'components/PDP/Details/components/PasControl/hooks/use-availability-step';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAlwaysOnFlexEnabled } from 'components/PDP/Details/components/PasControl/hooks/use-is-flex-always-on';
import { useLocation } from 'react-router-dom';
import { usePasData } from 'containers/PDPContainer/feature/pas/hooks/use-pas-data';
import { useResetPreviousSteps } from 'components/PDP/Details/components/PasControl/hooks/use-reset-previous-steps';
import { useRestoreOrderTypeIfDateAvailable } from 'components/PDP/Details/components/PasControl/hooks/use-restore-order-type-if-date-available';
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import i18next from 'i18next';

interface Props {
  hideAlert: () => void;
  arrangementId: number;
  product: Product | null;
  onChangeStep: (step: AvailabilityStep) => void;
  onSubmit: () => void;
  disabled?: boolean;
  scrollToStep: (step: AvailabilityStep) => void;
  onDeliveryCheck: (orderType: OrderType) => void;
  isOwnBoxEmpty?: boolean;
  zipRef?: MutableRefObject<HTMLInputElement | null>;
  fulfillmentRef?: MutableRefObject<HTMLInputElement | null>;
  dateRef?: MutableRefObject<HTMLInputElement | null>;
  storeRef?: MutableRefObject<HTMLInputElement | null>;
  suggestedDatesRef?: MutableRefObject<HTMLInputElement | null>;
  firstStoreAutoSelect: boolean;
  addOnIds: number[];
  clickOwnBoxAddon: OwnBoxAddon[];
  selectedOwnBoxAddons: UpsellItem[];
}

export const PasControl: FC<Props> = ({
  hideAlert,
  arrangementId,
  product,
  onChangeStep,
  onSubmit,
  disabled,
  scrollToStep,
  onDeliveryCheck,
  isOwnBoxEmpty = false,
  zipRef,
  fulfillmentRef,
  dateRef,
  storeRef,
  suggestedDatesRef,
  firstStoreAutoSelect,
  addOnIds,
  clickOwnBoxAddon,
  selectedOwnBoxAddons,
}) => {
  const dispatch = useDispatch();
  const pasData = usePasData();
  const location = useLocation();
  const state = location.state as {
    activeCartRecipientId: number;
    zipCode: string;
  };
  const user = useSelector(({ userSession: { data } }) => data);
  const isAlwaysOnFlexEnabled = useIsAlwaysOnFlexEnabled();
  const collectionPromptRef = useRef<HTMLInputElement | null>(null);

  const catalogNumber = useSelector(selectCatalogNumber);
  const areaAvailability = useSelector(getAreaAvailability);
  const region = useSelector(selectRegion);

  const dateAvailability = useSelector(selectDateAvailabilityData);
  const recipientId = useSelector(selectRecipientId);
  const isOrderTypeLock = useSelector(selectIsOrderTypeLock);
  const isLockExist = useSelector(selectIsLockExist);
  const isNotAvailableAndFulfilled = useSelector(
    selectIsNotAvailableAndFulfilled,
  );

  const [activeStepperStep, setActiveStepperStep] = useState<number>(zipStep);
  const [lastStepperStep, setLastStepperStep] = useState<number>(zipStep);
  const [area, setArea] = useState<Area | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [availability, setAvailability] = useState<AvailabilityOnDate | null>(
    dateAvailability,
  );
  const [orderType, setOrderType] = useState<ValidOrderTypes>(
    OrderType.NotSpecified,
  );
  const orderIsDelivery = isDelivery(orderType);
  const [suggestedDate, setSuggestedDate] = useState<Date | null>(null);

  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const [zipCodeFromPickupForm, setZipCodeFromPickupForm] =
    useState<string>('');

  const selectedAvailability =
    availability && orderType
      ? getAvailabilityByType(availability, orderType)
      : null;
  const availabilityStep = useAvailabilityStep({
    isProductOptionSelected: isNotNull(product),
    isZipEntered: isNotNull(area),
    isOrderTypeSelected: isFulfillType(pasData.orderType || orderType),
    isStoreSelected: isNotNull(pasData.store) && isPositive(pasData.store?.id),
    date: pasData.date ?? selectedDate,
    selectedAvailability,
  });

  const todayDate = new Date();

  const sameDayDelivery =
    selectedDate?.getDate() === todayDate.getDate() && orderIsDelivery;

  let isNonFlexFeeDates = false;
  if (availability) {
    isNonFlexFeeDates =
      sameDayDelivery &&
      !availability.delivery.isFlex &&
      availability.delivery.available &&
      isAlwaysOnFlexEnabled;
  }

  const mDayCollection = [
    7918, 6445, 7077, 8444, 9105, 7908, 9014, 9104, 9101, 9107, 9008, 9015,
    9039, 9100, 9106, 9040, 8445, 7904, 7917, 9013, 7224, 7902, 7903, 7909,
    8448, 8440, 6444, 9017, 9020, 8438, 7956, 7953, 7955, 7949, 8439, 9103,
    9006, 9102, 6479, 7072, 9009, 9010, 8970, 8971, 1047, 3075, 5001, 5002,
    5149, 5158, 9022, 9111, 9110, 6432, 8965, 9011, 9016, 9109, 9021, 9007,
    9012, 6431, 7008,
  ];

  const dateGuard = selectedDate ? formatSQLDate(selectedDate) : '';
  const vDayCollection =
    dateGuard === '2024-05-11' || dateGuard === '2024-05-12';

  const mDayCollectionItem = mDayCollection.includes(catalogNumber);

  const shippingZip = areaAvailability.shipment;
  const deliveryZip = areaAvailability.delivery;
  const pickupZip = areaAvailability.pickup;

  const displayCarousel =
    !mDayCollectionItem && !!shippingZip && deliveryZip && pickupZip;

  const autoSelect = useAutoSelect(orderType);
  useResetPreviousSteps({
    setSelectedDate,
    setAvailability,
    setActiveStepperStep,
    setOrderType,
    area,
    selectedDate,
    orderType,
    suggestedDate,
  });

  const toggleActiveStepperStep = (step: number) => {
    if (step === activeStepperStep) {
      setActiveStepperStep(notSelectedStep);
      return;
    }
    setActiveStepperStep(step);
  };

  useEffect(() => {
    setAvailability(dateAvailability);
  }, [dateAvailability]);

  useEffect(() => {
    setArea(pasData.area);
    setSelectedDate(pasData.date);
    setOrderType(pasData.orderType);
  }, [pasData]);

  useRestoreOrderTypeIfDateAvailable({
    setOrderType,
  });

  useEffect(() => {
    if (selectedDate && suggestedDate) {
      if (formatSQLDate(selectedDate) === formatSQLDate(suggestedDate)) {
        setSuggestedDate(null);
      } else {
        setSelectedDate(suggestedDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedDate, selectedDate]);

  useEffect(() => {
    if (state && !!state.zipCode && !pasData.area?.name) {
      setZipCodeFromPickupForm(state.zipCode);
      if (user && user?.cartId)
        dispatch(getCartCount({ cartId: user?.cartId, region }));
    } else {
      setZipCodeFromPickupForm('');
    }
  }, [state, pasData, user]);

  useEffect(() => {
    dispatch(resetSuggestedArrangements());
  }, [dateGuard, area, dispatch]);

  useEffect(() => {
    onChangeStep(availabilityStep);
    onDeliveryCheck(orderType);
    setActiveStepperStep(steps[availabilityStep]);
    setLastStepperStep(steps[availabilityStep]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityStep, orderType]);

  const onSelectStore = (store: Store) => {
    dispatch(
      pas.set({
        area,
        date: selectedDate,
        orderType,
        store,
      }),
    );

    if (area && (!isLockExist || isOrderTypeLock)) {
      dispatch(
        updateAvailabilitySession({
          storeId: store.id,
          areaId: area.id,
          areaName: area.name,
          selectedDate,
          serviceOption: orderType,
          currentRecipientId: recipientId,
        }),
      );
    }
    if (
      addOnIds.length === 3 &&
      isValidateDessertBoardItems(
        selectedOwnBoxAddons,
        clickOwnBoxAddon,
        onChangeStep,
        pasData,
      )
    ) {
      scrollToStep(AvailabilityStep.DESSERT_OWN_BOX_ADDONS);
      return;
    }
    if (isOwnBoxEmpty) {
      scrollToStep(AvailabilityStep.OWN_BOX_ADDONS);
      return;
    }
    if (autoSelect && isLockExist) {
      return;
    }
    onSubmit();
  };

  if (disabled) {
    return null;
  }

  const onDateSelect = (possiblyDate: Date | null) => {
    appInsights.trackEvent({
      name: 'PAS date selected',
      properties: {
        date: possiblyDate ? formatSQLDate(possiblyDate) : 'null',
      },
    });
    setSelectedDate(possiblyDate);
    if (dateAvailability) {
      segment.trackFulfillmentMethod(
        area?.name,
        possiblyDate ?? null,
        dateAvailability,
        dateAvailability.pickup.available,
        dateAvailability.delivery.available,
        dateAvailability.shipment.available &&
          !dateAvailability.pickup.available &&
          !dateAvailability.delivery.available,
        !!(area?.name !== '' && possiblyDate !== null),
        userSession,
        userAccountInformation,
      );
    }
  };

  return (
    <>
      <Divider />
      <MarginWrapper my={4}>
        <Typography variant="h3" fontWeight={600} fontSize={20} mb={2}>
          {i18next.t(TK.TITLE)}
        </Typography>
        <Stepper orientation="vertical" connector={null}>
          <Step
            active={activeStepperStep === zipStep}
            completed={activeStepperStep > zipStep}
            disabled={lastStepperStep < zipStep || isOrderTypeLock}
          >
            <ZipAvailability
              hideAlert={hideAlert}
              defaultValue={pasData.area?.name}
              onSubmit={setArea}
              setActiveStepperStep={toggleActiveStepperStep}
              isStepClickable={lastStepperStep >= zipStep && !isOrderTypeLock}
              zipRef={zipRef}
              zipCodeFromPickupForm={zipCodeFromPickupForm}
            />
          </Step>

          <Step
            active={activeStepperStep === dateStep}
            completed={activeStepperStep > dateStep}
            disabled={lastStepperStep < dateStep || isOrderTypeLock}
          >
            <DateSelection
              defaultValue={selectedDate}
              onSelect={onDateSelect}
              area={area}
              arrangementId={arrangementId}
              disabled={isAvailabilityStepGreaterThan(
                AvailabilityStep.DATE_SELECTION,
                availabilityStep,
              )}
              setActiveStepperStep={toggleActiveStepperStep}
              isStepClickable={lastStepperStep >= dateStep && !isOrderTypeLock}
              dateRef={dateRef}
            />
          </Step>

          <Step
            active={activeStepperStep >= orderTypeStep}
            completed={activeStepperStep > orderTypeStep}
            disabled={
              lastStepperStep < orderTypeStep || isNotAvailableAndFulfilled
            }
          >
            <StepSummary
              availabilityStep={AvailabilityStep.DELIVERY_METHOD_SELECTION}
              setActiveStep={toggleActiveStepperStep}
              clickable={
                lastStepperStep >= orderTypeStep && !isNotAvailableAndFulfilled
              }
              preview={<Preview orderType={orderType} />}
            >
              {i18next.t(TK.DELIVERY_METHOD_QUESTION)}
            </StepSummary>
            <StepContent>
              <DeliveryMethods
                orderType={orderType}
                setOrderType={setOrderType}
                availability={availability}
                hidden={isOrderTypeLock}
                fulfillmentRef={fulfillmentRef}
                collectionPromptRef={collectionPromptRef}
              />
              {vDayCollection && displayCarousel && (
                <CollectionPrompt
                  orderType={orderType}
                  date={selectedDate}
                  area={area}
                  product={product}
                />
              )}
              {isFulfillType(orderType) &&
                selectedAvailability &&
                shouldShowSuggestedArrangements(selectedAvailability) && (
                  <SuggestedArrangements
                    orderType={orderType}
                    availability={availability}
                    date={selectedDate}
                    area={area}
                    onBeforeNavigation={() => setAvailability(null)}
                  />
                )}
              {isFulfillType(orderType) &&
                selectedAvailability &&
                shouldShowCalendar(
                  selectedAvailability,
                  orderIsDelivery,
                  isNonFlexFeeDates,
                ) && (
                  <SuggestedDates
                    availableDates={selectedAvailability.dates}
                    setSelectedDate={(dateValue) => {
                      setSuggestedDate(dateValue);
                    }}
                    dateSelected={selectedDate}
                    orderType={orderType}
                    isAlwaysOnFlex={isNonFlexFeeDates}
                    suggestedDatesRef={suggestedDatesRef}
                    collectionPromptRef={collectionPromptRef}
                    area={area}
                    product={product}
                  />
                )}
              {isFulfillType(orderType) &&
                selectedAvailability?.available &&
                activeStepperStep >= orderTypeStep && (
                  <Element
                    name={
                      AvailabilityStep[AvailabilityStep.STORE_LIST_SELECTION]
                    }
                  >
                    <StoreSelector
                      area={area}
                      date={selectedDate}
                      selectedStore={orderIsDelivery ? pasData.store : null}
                      orderType={orderType}
                      productId={product?.id ?? null}
                      arrangementId={arrangementId}
                      onSelect={onSelectStore}
                      disableAutoSelect={shouldShowFlexCalendar(
                        selectedAvailability,
                        orderIsDelivery,
                        isNonFlexFeeDates,
                      )}
                      isNonFlexFeeDates={isNonFlexFeeDates}
                      storeRef={storeRef}
                      defaultFirstStoreAutoSelect={firstStoreAutoSelect}
                    />
                  </Element>
                )}
            </StepContent>
          </Step>
        </Stepper>
      </MarginWrapper>
    </>
  );
};
