import { Box, Link, Skeleton, Typography } from '@mui/material';
import { isCanada } from 'utils/country';
import {
  mainMenu,
  menuItem,
  textStyle,
} from 'components/Header/Menus/CountryMenu/styles';
import React, { FC } from 'react';

type Props = {
  value: string;
  icon?: string;
  url?: string;
  isMenu?: boolean;
};

export const ButtonContent: FC<Props> = ({
  icon,
  value,
  url,
  isMenu = false,
}) => {
  return (
    <Box component="span" sx={isMenu ? mainMenu : menuItem}>
      {isCanada ? (
        <>
          <Link
            href={url}
            target="_blank"
            width="100%"
            display="flex"
            style={{ textDecoration: 'none' }}
          >
            {icon && <img src={icon} alt={value} />}
            <Link href={url} target="_blank" sx={textStyle}>
              <Typography variant="subtitle2" lineHeight="24px">
                {value}
              </Typography>
            </Link>
          </Link>
        </>
      ) : (
        <Skeleton width={55} height={20} />
      )}
    </Box>
  );
};
