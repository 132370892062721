import * as TK from 'translations/locales/translation-keys.constant';
import { BottomContinuePanel } from 'components/PDP/Details/containers/BottomContinuePanel/BottomContinuePanel';
import { BottomContinuePanelSkeleton } from 'components/PDP/Details/containers/BottomContinuePanel/BottomContinuePanelSkeleton';
import { Box, Grid, Typography } from '@mui/material';
import { ContinueButton } from 'components/PDP/Details/components/ContinueButton/ContinueButton';
import { ContinueButtonSkeleton } from 'components/PDP/Details/components/ContinueButton/ContinueButtonSkeleton';
import { KeyConst } from 'components/Paypal/enums/key.enum';
import { MarketplaceProductShipping } from 'components/PDP/Details/components/Marketplace/components/MarketplaceProductShipping/MarketplaceProductShipping';
import { OptionsAvailability } from 'components/PDP/Details/components/ProductOptions/hooks/use-product-options-availability';
import {
  PayLaterMessage,
  calculatePayLaterAmount,
} from 'components/Paypal/PayLaterMessage';
import { PaypalSkeleton } from 'components/Paypal/PaypalSkeleton';
import { Product } from 'redux/PDP/arrangement/types/arrangement.types';
import { ProductOption } from 'components/PDP/Details/components/ProductOptions/components/ProductOption/ProductOption';
import { ProductOptionsSkeleton } from 'components/PDP/Details/components/ProductOptions/components/ProductOptionsSkeleton';
import { Quantity } from 'components/PDP/Details/components/ProductOptions/components/Quantity/Quantity';
import { QuantityButtonSkeleton } from 'components/PDP/Details/components/ProductOptions/components/Quantity/QuantityButtonSkeleton';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { UpsellItem } from 'components/PDP/Upsells/feature/types/upsell-item';
import { ViewAvailableProductsAlert } from 'components/PDP/Details/components/ProductOptions/components/ViewAvailableProductsAlert/ViewAvailableProductsAlert';
import {
  checkIsBlocked,
  selectAlt,
  selectIsMarketplaceProduct,
  selectProducts,
} from 'redux/PDP/arrangement/arrangement.selectors';
import { colors } from 'utils/theme/colors';
import { dateCheckIfIncludesTime } from 'utils/date';
import { format } from 'date-fns';
import { getSearchParams } from 'utils/getSearchParams';
import { isNotEmpty } from 'utils/array/size';
import {
  payPalEnabled,
  payPalVersion,
} from 'redux/settings/settings.selectors';
import { segment } from 'service/segment/segment';
import {
  selectIsAreaError,
  selectIsAreaPending,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.selectors';
import { selectIsLoading } from 'components/PDP/Details/components/ProductOptions/feature/selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';
import i18next from 'i18next';

type Props = {
  unavailable?: boolean;
  setProduct: (product: Product) => void;
  selectedProduct: Product | null;
  quantity: number;
  setQuantity: (quantity: number) => void;
  anyOptionAvailable?: boolean;
  onContinue: (event?: React.MouseEvent) => void;
  optionsAvailability: OptionsAvailability;
  salePriceFG: string;
  isCYOBox?: boolean;
  setOwnBoxAddons?: (addons: UpsellItem[]) => void;
};

const updateSizeParam = (name: string) => {
  const url = new URL(window.location as unknown as string);
  url.searchParams.set('size', name);
  window.history.replaceState(null, '', url.toString());
};

export const ProductOptions: FC<Props> = ({
  quantity,
  setQuantity,
  unavailable = false,
  setProduct,
  selectedProduct,
  onContinue,
  anyOptionAvailable,
  optionsAvailability,
  salePriceFG,
  isCYOBox,
  setOwnBoxAddons,
}) => {
  const isPayPalEnabled = useSelector(payPalEnabled);
  const isPayPalVersion = useSelector(payPalVersion);

  const { ref: continueButtonRef, inView: isMainContinueButtonInView } =
    useInView();

  const isAreaError = useSelector(selectIsAreaError);
  const isAreaLoading = useSelector(selectIsAreaPending);
  const products = useSelector(selectProducts);
  const alt = useSelector(selectAlt);
  const isArrangementBlocked = useSelector(checkIsBlocked);
  const isAvailabilityLoading = useSelector(selectIsLoading);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const isMarketplaceProduct = useSelector(selectIsMarketplaceProduct);

  const isLoading = isArrangementBlocked || isAvailabilityLoading;
  const isDisabled = isLoading || isAreaLoading;
  /* TODO: move outside of the product options after old PAS is removed */
  const isContinueBlocked = isDisabled || isAreaError;
  const salePriceColor = salePriceFG.length < 4 ? colors.promoRed : salePriceFG;
  const productSizeSearchParams = getSearchParams('size');

  useEffect(() => {
    if (
      !isAvailabilityLoading &&
      isNotEmpty(products) &&
      optionsAvailability[products[0].id] &&
      !selectedProduct
    ) {
      setProduct(products[0]);
      setOwnBoxAddons?.([]);
    }
  }, [
    optionsAvailability,
    isAvailabilityLoading,
    setProduct,
    products,
    selectedProduct,
  ]);

  const validateArrangementSizeActiveDate = (arrangementProduct: Product) => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    if (
      arrangementProduct.arrangementSizeActiveFromDate &&
      arrangementProduct.arrangementSizeActiveToDate
    ) {
      const activeFromDate = format(
        dateCheckIfIncludesTime(
          arrangementProduct.arrangementSizeActiveFromDate.toLocaleString(),
        ),
        'yyyy-MM-dd',
      );
      const activeToDate = format(
        dateCheckIfIncludesTime(
          arrangementProduct.arrangementSizeActiveToDate.toLocaleString(),
        ),
        'yyyy-MM-dd',
      );
      return currentDate >= activeFromDate && currentDate <= activeToDate;
    }
    if (
      arrangementProduct.arrangementSizeActiveFromDate &&
      !arrangementProduct.arrangementSizeActiveToDate
    ) {
      const activeFromDate = format(
        dateCheckIfIncludesTime(
          arrangementProduct.arrangementSizeActiveFromDate.toLocaleString(),
        ),
        'yyyy-MM-dd',
      );
      return currentDate >= activeFromDate;
    }
    return true;
  };

  useEffect(() => {
    if (products.length > 0 && !productSizeSearchParams) {
      const prod = products
        .map((product) => {
          return validateArrangementSizeActiveDate(product)
            ? product
            : undefined;
        })
        .filter((item) => item !== undefined);
      if (prod[0]) {
        updateSizeParam(prod[0].name);
      } else {
        updateSizeParam(products[0].name);
      }
    }
  }, [products, productSizeSearchParams]);

  useEffect(() => {
    if (productSizeSearchParams) {
      const filterProduct = products.filter(
        (product) =>
          product.name.toUpperCase() === productSizeSearchParams.toUpperCase(),
      );
      if (isNotEmpty(filterProduct)) {
        setProduct(filterProduct[0]);
      }
    }
  }, [products, setProduct, productSizeSearchParams]);

  const checkShouldHighlightProduct = (
    productId: number,
    order: number,
  ): boolean => {
    if (unavailable) {
      return false;
    }
    if (selectedProduct) {
      return selectedProduct.id === productId;
    }
    return order === 0 ? Boolean(anyOptionAvailable) : false;
  };
  const showContent = !unavailable && !isLoading;

  const showPayLaterMessage =
    showContent &&
    isPayPalEnabled &&
    isPayPalVersion === KeyConst.PaypalVersion;

  const handleSelect = (product: Product) => () => {
    setProduct(product);
    setOwnBoxAddons?.([]);
    segment.productVariantClicked(product, userSession, userAccountInformation);
    updateSizeParam(product.name);
  };

  return (
    <>
      <div>
        {(isCYOBox && products.length === 2) || !isCYOBox ? (
          <>
            {showContent && (
              <Typography mb={1} variant="subtitle1" component="h2">
                {i18next.t(
                  products.length > 1 ? TK.CHOOSE_VARIANT : TK.SINGLE_VARIANT,
                )}
              </Typography>
            )}

            {isLoading ? (
              <ProductOptionsSkeleton />
            ) : (
              <Grid container spacing={2} role="radiogroup">
                {products.map((product, i) => {
                  const validateArrangementSize =
                    products.length > 1
                      ? validateArrangementSizeActiveDate(product)
                      : true;
                  return (
                    validateArrangementSize && (
                      <ProductOption
                        dataTest={`pdp-details-product-size-${i + 1}`}
                        key={product.id}
                        onSelect={handleSelect(product)}
                        product={product}
                        selected={checkShouldHighlightProduct(product.id, i)}
                        imageAltTag={alt}
                        unavailable={
                          unavailable || !optionsAvailability[product.id]
                        }
                        disabled={isDisabled}
                        salePriceFG={salePriceColor}
                        isMarketplaceProduct={isMarketplaceProduct}
                      />
                    )
                  );
                })}
              </Grid>
            )}
          </>
        ) : null}

        {!isCYOBox && (
          <>
            {showPayLaterMessage && (
              <>
                {selectedProduct ? (
                  <Box mt={3}>
                    <PayLaterMessage
                      amount={calculatePayLaterAmount(
                        selectedProduct.price,
                        quantity,
                      )}
                    />
                  </Box>
                ) : (
                  <PaypalSkeleton />
                )}
              </>
            )}
            {isMarketplaceProduct && <MarketplaceProductShipping />}
            <Box
              mt={3}
              display="flex"
              flexDirection={['column', 'row']}
              justifyContent="space-between"
            >
              <Box width={1}>
                {showContent && selectedProduct ? (
                  <Quantity
                    quantity={quantity}
                    setQuantity={setQuantity}
                    disabled={isDisabled}
                    selectedProduct={selectedProduct}
                  />
                ) : (
                  <QuantityButtonSkeleton />
                )}
              </Box>
              <Box width={1} ml={[0, 2]}>
                {showContent ? (
                  <ContinueButton
                    ref={continueButtonRef}
                    onClick={onContinue}
                    disabled={isContinueBlocked}
                    dataTest="pdp-details-continue-button"
                    selectedProduct={selectedProduct || undefined}
                    quantity={quantity}
                    setQuantity={setQuantity}
                  />
                ) : (
                  <ContinueButtonSkeleton ref={continueButtonRef} />
                )}
              </Box>
            </Box>
          </>
        )}

        {!anyOptionAvailable && !isLoading && !isCYOBox && (
          <Box mt={2}>
            <ViewAvailableProductsAlert />
          </Box>
        )}
      </div>

      {!isCYOBox && (
        <ShowUpSm>
          {isLoading && !isMainContinueButtonInView ? (
            <BottomContinuePanelSkeleton />
          ) : (
            <BottomContinuePanel
              selectedVariantName={selectedProduct?.name ?? ''}
              quantity={quantity}
              onContinue={onContinue}
              isBlocked={isContinueBlocked}
              reveal={!isMainContinueButtonInView}
              selectedProduct={selectedProduct || undefined}
              setQuantity={setQuantity}
            />
          )}
        </ShowUpSm>
      )}
    </>
  );
};
