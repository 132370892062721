import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/OrderType/style';
import { Box, Link, Typography } from '@mui/material';
import { CheckImg } from 'utils/cloudinaryAssets';
import { HeaderLinks } from 'enums/header-links';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Order } from 'redux/order-confirmation/types/types';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { formatToReadableDateWithoutTime } from 'utils/date';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  order: Order;
}

export const Shipment: FC<Props> = ({ order }) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={styles.wrapper}
    key={order.orderID}
  >
    <Typography sx={styles.title}>
      {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_TITLE)}
    </Typography>
    <Box mb={[3, 3, 0]} className="rightStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_METHOD_NAME)}
      </Typography>
      <Typography sx={styles.infoText}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_METHOD)}
      </Typography>
      <ShowUpMd>
        <Typography sx={styles.infoText} className="shipmentInfo">
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_MORE_INFO)},{' '}
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_INFO)}:{' '}
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_CUSTOMER_CARE)}
        </Typography>
      </ShowUpMd>
      <HideUpMd>
        <Typography sx={styles.infoText} className="shipmentInfo">
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_MORE_INFORMATION)},{' '}
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_INFO)}:{' '}
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_CUSTOMER_CARE)}
        </Typography>
      </HideUpMd>
    </Box>
    <Box mb={[3, 3, 0]} className="leftStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_ON)}
      </Typography>
      <Typography sx={styles.infoText}>
        {formatToReadableDateWithoutTime(
          new Date(order.fulfillmentDate.toString().replace('Z', '')),
        )}
      </Typography>
    </Box>
    <Box mb={[3, 3, 0]} className="rightStyle">
      <Typography sx={styles.infoTitle}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_DELIVERY_INFO_TO)}
      </Typography>
      <Typography sx={styles.infoText}>{order.recipientName}</Typography>
      <Typography sx={styles.infoText} style={{ marginTop: '5px' }}>
        {order.address1}
      </Typography>
      <Typography sx={styles.infoText} style={{ marginTop: '5px' }}>
        {order.city}, {order.stateAbbreviation} {order.area}
      </Typography>
      <Typography sx={styles.infoText}>{order.workPhone}</Typography>
      <Typography sx={styles.infoText}>{order.homePhone}</Typography>
      <Typography sx={styles.infoText}>{order.cellPhone}</Typography>
    </Box>
    <Box className="rightStyle" mb={[0, 4, 0]}>
      <img
        alt="icon"
        width={20}
        height={20}
        className="checkImg"
        src={CheckImg}
      />
      <Link href={HeaderLinks.SHIPMENT_POLICY} sx={styles.deliveryLink}>
        {i18next.t(TK.ORDER_CONFIRMATION_PAGE_SHIPMENT_POLICY)}
      </Link>
    </Box>
  </Box>
);
