import { SxProps } from '@mui/system';
import { colors } from 'utils/theme/colors';
import { theme } from 'utils/theme/theme';

export const wrapper: SxProps = {
  minWidth: 290,
  marginLeft: ['0', '25%', '14%'],
  marginBottom: '20px',
  background: '#F7F7F7',
};

export const button: SxProps = {
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    alignItems: 'flex-start',
  },

  padding: '10px',
  '& img': {
    height: 20,
    width: 20,
    mr: 1,
  },
};
export const menuItem: SxProps = {
  display: 'flex',
  alignItems: 'center',
  width: ['340px', '240px'],
  '& img': {
    height: 24,
    width: 24,
    mr: 1,
  },
  '& h6': {
    fontWeight: 400,
  },
};
export const mainMenu: SxProps = {
  display: 'flex',
  width: '100%',
  '& img': {
    height: 24,
    width: 24,
    mr: 1,
  },
  '& h6': {
    fontWeight: 600,
  },
};

export const textStyle: SxProps = {
  textDecoration: 'none',
  color: colors.placeholderText,
};
