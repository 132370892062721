import {
  CA_ZIP_LENGTH,
  ZIP_LENGTH,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.constants';
import { isUS } from 'utils/country';

export function isFullZip(zip: string): boolean {
  return zip.length === (isUS ? ZIP_LENGTH : CA_ZIP_LENGTH);
}
function isNumeric(str: string): boolean {
  return /^[0-9]+$/.test(str);
}
export function isUsZip(zip: string): boolean {
  return zip.length === ZIP_LENGTH && isNumeric(zip);
}

export function sanitizeZip(zip: string): string {
  const postalCodeRegex = new RegExp(
    /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i,
  );
  const zipValue = zip.replace(/[^\w\s]/gi, '');

  if (isUS) {
    return zipValue.replace(/[^0-9]/g, '');
  }

  if (postalCodeRegex.test(zipValue) && zipValue.length === 6) {
    return `${zipValue.substring(0, 3)} ${zipValue.substring(3, 6)}`;
  }

  return zipValue;
}
