import * as TK from 'components/Header/Menus/translations/header-menus.locale.constants';
import * as TK1 from 'components/Header/Menus/CountryMenu/translations/menus.locale.constants';
import {
  AllCountry,
  Countries,
  Language,
  Languages,
  LocaleState,
} from 'redux/locale/locale.types';
import { Country, getCurrentCountry } from 'utils/country';
import { Status } from 'redux/types/state.interface';
import {
  canadaIcon,
  canadianFlag,
  qatarIcon,
  uaeIcon,
  unitedStates,
  usaIcon,
} from 'utils/cloudinaryAssets';
import { changeLocale } from 'redux/locale/locale.actions';
import { createSlice } from '@reduxjs/toolkit';
import { loadLocale } from 'redux/locale/utils';
import i18n from 'i18next';

export const languages: Record<string, Language> = {
  [Languages.ENGLISH]: {
    definition: i18n.t(TK.LOCALE_ENGLISH),
    acronym: 'ENGLISH',
    icon: getCurrentCountry() === Country.CANADA ? canadianFlag : unitedStates,
  },
  [Languages.FRENCH]: {
    definition: i18n.t(TK.LOCALE_FRENCH),
    acronym: 'FRENCH',
    icon: canadianFlag,
  },
};

export const countries: Record<string, AllCountry> = {
  [Countries.CA]: {
    definition: i18n.t(TK1.CANADA),
    acronym: 'Canada',
    icon: canadaIcon,
  },
  [Countries.QA]: {
    definition: i18n.t(TK1.QATAR),
    acronym: 'Qatar',
    icon: qatarIcon,
  },
  [Countries.UAE]: {
    definition: i18n.t(TK1.UAE),
    acronym: 'United Arab Emirates',
    icon: uaeIcon,
  },
  [Countries.USA]: {
    definition: i18n.t(TK1.USA),
    acronym: 'United States',
    icon: usaIcon,
  },
};

const initialState: LocaleState = {
  data: loadLocale(),
  status: Status.INIT,
};

const localeSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changeLocale.pending, () => ({
        ...initialState,
        status: Status.PENDING,
      }))
      .addCase(changeLocale.rejected, () => ({
        ...initialState,
        status: Status.ERROR,
      }))
      .addCase(changeLocale.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.FULFILLED;
      })
      .addDefaultCase((state) => state);
  },
});

export const { reducer: localeReducer } = localeSlice;
