import { FLEX_FREE } from 'translations/locales/translation-keys.constant';
import { Store } from 'redux/types/store.types';
import { Typography } from '@mui/material';
import { addCurrency } from 'utils/add-currency';
import {
  selectIsFulfilled,
  selectStores,
} from 'components/PDP/Details/components/PasControl/components/StoreSelector/feature/store-selector.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

type Props = {
  fee: number;
  isDelivery: boolean;
  isSameDay: boolean;
  isOnlyShipmentAvailable: boolean;
};

export const FlexFee: FC<Props> = ({
  fee,
  isDelivery,
  isSameDay,
  isOnlyShipmentAvailable,
}) => {
  const [sameDayDeliveryFee, setSameDayDeliveryFee] = useState<number>(0);
  const isStoresFulfilled = useSelector(selectIsFulfilled);
  const stores: Store[] = useSelector(selectStores);
  useEffect(() => {
    if (isStoresFulfilled) {
      setSameDayDeliveryFee(
        (stores && stores.length > 0 && stores[0].deliveryFee) || 0,
      );
    }
  }, [isStoresFulfilled, stores]);
  const deliveryFee =
    (sameDayDeliveryFee !== 0 || fee !== 0) &&
    (isSameDay
      ? `+${addCurrency(sameDayDeliveryFee || fee)}*`
      : `+${addCurrency(fee)}`);

  return (
    <Typography
      variant="subtitle2"
      color={fee === 0 && isDelivery ? 'primary' : undefined}
    >
      {!isOnlyShipmentAvailable && (deliveryFee || i18next.t(FLEX_FREE))}
    </Typography>
  );
};
