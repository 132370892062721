import * as TK from 'components/Error/translations/constants';
import * as styles from 'components/Error/OrderConfirmationError/styles';
import { Box, Button, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { Shaky } from 'components/Error/OrderConfirmationError/styles';
import { appInsights } from 'utils/telemetry/app-insights';
import { segment } from 'service/segment/segment';
import { shockedStrawberry } from 'utils/cloudinaryAssets';
import React, { useEffect } from 'react';
import i18next from 'i18next';

export function RewardsPageError(props: FallbackProps): JSX.Element {
  useEffect(() => {
    appInsights.trackEvent({ name: 'Rewards Page Error' }, props.error);
    segment.pageViewed('Error Page Viewed - Rewards Page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Shaky>
        <img
          src={shockedStrawberry}
          width={200}
          height={200}
          alt={i18next.t(TK.ERROR_STRAWBERRY_MASCOT)}
        />
      </Shaky>
      <Typography variant="h2">
        {i18next.t(TK.REWARDS_PAGE_ERROR_TITLE)}
      </Typography>
      <Typography variant="body2">
        {i18next.t(TK.REWARDS_PAGE_ERROR_SUMMARY)}
      </Typography>
      <Box display="flex" sx={{ gap: '30px' }}>
        <Button variant="contained" onClick={props.resetErrorBoundary}>
          {i18next.t(TK.REWARDS_PAGE_ERROR_RELOAD)}
        </Button>
      </Box>
    </Box>
  );
}
