import * as styles from 'components/Header/Menus/CountryMenu/styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Button, Divider, Menu, MenuItem } from '@mui/material';
import { ButtonContent } from 'components/Header/Menus/CountryMenu/ButtonContent';
import { Countries } from 'redux/locale/locale.types';
import { countries } from 'redux/locale/locale.slice';
import { useLocale } from 'hooks/use-locale';
import { useMenu } from 'hooks/useMenu';
import React, { FC, useEffect } from 'react';

export const CountryMenu: FC = () => {
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const { locale } = useLocale();
  const activeCountry = countries[Countries.CA];

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, [open]);

  return (
    <Box sx={styles.wrapper}>
      <Button
        color="secondary"
        id="Country-menu-button"
        aria-controls={open ? 'Country-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={styles.button}
      >
        <ButtonContent
          value={activeCountry.definition}
          icon={activeCountry.icon}
          isMenu
        />
        <Divider
          style={{ color: 'black', width: 3, marginLeft: '149px' }}
          orientation="vertical"
          flexItem
        />
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </Button>
      <Menu
        id="Country-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'Country-menu-button',
        }}
      >
        {Object.keys(countries).map((country) => {
          const countryItem = countries[country];
          if (countryItem === activeCountry) {
            return null;
          }
          return (
            <MenuItem
              key={country}
              disabled={country === locale}
              onClick={() => handleClose()}
            >
              <ButtonContent
                value={countryItem.definition}
                icon={countryItem.icon}
                url={country}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
