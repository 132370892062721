import { fetchReviews } from 'components/PDP/Details/components/Reviews/feature/reviews.actions';
import { isUS } from 'utils/country';
import { resetReviewsState } from 'components/PDP/Details/components/Reviews/feature/reviews.slice';
import { selectCatalogNumber } from 'redux/PDP/arrangement/arrangement.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useIsBvEnabled } from 'lib/bazaarvoice/hooks/use-is-bv-and-c8-enabled';

export const useArrangementReviews = (): void => {
  const dispatch = useDispatch();
  const productCode = useSelector(selectCatalogNumber);
  const isBvEnabled = useIsBvEnabled();

  useEffect(() => {
    if (isBvEnabled && isUS) {
      return;
    }
    if (!productCode) {
      dispatch(resetReviewsState());
      return;
    }

    dispatch(resetReviewsState());
    dispatch(fetchReviews({ productCode }));
  }, [productCode, dispatch, isBvEnabled]);
};
