import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/CreateAccount/styles';
import { AccountBannerLogin } from 'components/OrderConfirmation/CreateAccount/AccountBannerLogin';
import { AccountBannerSignUp } from 'components/OrderConfirmation/CreateAccount/AccountBannerSignUp';
import { Box, Button, Typography } from '@mui/material';
import { analytics } from 'service/adobe-analytics/analytics';
import { checkIsOrderConfirmation } from 'redux/order-confirmation/selectors';
import { selectIsLoggedIn } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';
import i18next from 'i18next';

export interface Props {
  IsOrderDetails?: boolean;
}

export const Account: FC<Props> = ({ IsOrderDetails = false }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loading = useSelector(checkIsOrderConfirmation);
  const user = useSelector(({ userSession: { data } }) => data);

  if (IsOrderDetails) {
    return (
      <>
        <Box sx={styles.orderDetailsHead}>
          <Typography sx={styles.shopTitle}>
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_NEED_MORE_SHOPPING)}
          </Typography>
          <Button
            variant="text"
            type="button"
            size="small"
            data-test="start-new-order-button"
            aria-label="start-new-order"
            className="startOrderBtn"
            sx={styles.btnStyle}
            onClick={() => {
              analytics.startNewOrderClicked(
                user?.userId > 0 ? user?.userId : 0,
              );
            }}
            href="/"
          >
            {i18next.t(TK.ORDER_CONFIRMATION_PAGE_NEW_ORDER)}
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={styles.shopHead}>
        <Typography sx={styles.shopTitle}>
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_NEED_MORE_SHOPPING)}
        </Typography>
        <Button
          variant="text"
          type="button"
          size="small"
          data-test="start-new-order-button"
          aria-label="start-new-order"
          className="startOrderBtn"
          sx={styles.btnStyle}
          onClick={() => {
            analytics.startNewOrderClicked(user?.userId > 0 ? user?.userId : 0);
          }}
          href="/"
        >
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_NEW_ORDER)}
        </Button>
      </Box>
      <Box display={loading ? 'none' : 'inline'} sx={styles.accountCont}>
        {isLoggedIn ? <AccountBannerSignUp /> : <AccountBannerLogin />}
      </Box>
    </>
  );
};
