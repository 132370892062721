import { SxProps, Theme } from '@mui/material';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';

export const itemImage: SxProps = {
  border: `1px solid ${colors.dividerBlack}`,
};

export const productImageTagText: SxProps = {
  position: 'absolute',
  top: '0',
  fontWeight: '400',
  fontFamily: fonts.poppins,
  fontSize: ['8px', '8px', '16px'],
  lineHeight: ['13.12px', '13.12px', '24px'],
  backgroundColor: '#E10600',
  padding: ['0px 6px', '0px 6px', '2px 6px'],
  margin: '10px',
  color: '#ffffff',
};

export const productSuggestImageTagText: SxProps = {
  position: 'absolute',
  top: '0',
  fontWeight: '400',
  fontFamily: fonts.poppins,
  fontSize: '8px',
  lineHeight: '13.12px',
  backgroundColor: '#E10600',
  padding: '0px 6px',
  margin: '10px',
  color: '#ffffff',
};

export const size: ThemedSxProps = (theme: Theme) => ({
  pt: 0.5,
  color: theme.palette.text.secondary,
});

export const promo = (color?: string): SxProps<Theme> => ({
  color: (t) => color ?? t.palette.primary.main,
  mt: 1,
});

export const regularPrice: ThemedSxProps = {
  textDecoration: 'line-through',
  mr: 1,
  color: (theme) => theme.palette.text.secondary,
};
export const salePrice =
  (color?: string): ThemedSxProps =>
  (theme) => ({
    color: color ?? theme.palette.primary.main,
    pb: 0.5,
    display: 'inline-block',
  });

export const InterrupterWrapper: SxProps = {
  width: '100%',
  height: '100%',
  maxWidth: ['100%', '768px', '100%'],
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  justifyContent: 'flex-end',
  flex: '1 0 0',
  alignSelf: 'stretch',
};

export const OverlayLinkPadding: SxProps = {
  padding: '8.5px 0px 0px 0 !important',
};
