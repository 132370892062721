export type Filter = {
  ID: number;
  Name: string;
  FilterCategoryID: number;
  Value: string;
  Type: number;
  Sequence: number;
  PageFriendlyURL: string;
  FilterImageURL?: string;
};

export type FilterCategory = {
  ID: number;
  Name: string;
  SingleSelection: boolean;
  SortedBy: number;
  Items: Filter[];
  FilterCategoryImageURL: string;
};
export type FruitFilter = {
  Name: string;

  ProductGroupID: number;
};

export enum Type {
  megaMenu = 1,
  fruitBasketPage = 2,
  dfOptions = 3,
  giftFinder = 4,
  footerMenu = 8,
}

export enum FilterType {
  allFilter = 0,
  priceFilter = 8,
  priceFilterCa = 9,
  occasionFilterType = 5,
  occasionFilter = 9,
  occasionFilterCa = 8,
}

export type Types = typeof Type[keyof typeof Type];
