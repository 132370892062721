import { FilterType } from 'api/filter-categories/types';
import { RootState } from 'redux/store';
import { Status } from 'redux/types/state.interface';
import { createSelector } from '@reduxjs/toolkit';
import { isCanada } from 'utils/country';

export const checkIsBlocked = createSelector(
  ({ plpFilterCategories: { status } }: RootState) => status,
  (status) => [Status.INIT, Status.PENDING].includes(status),
);

export const checkIsInit = createSelector(
  ({ plpFilterCategories: { status } }: RootState) => status,
  (status) => Status.INIT === status,
);

export const checkIsInitFruitFilters = createSelector(
  ({ plpFilterCategories: { fruitFilterStatus } }: RootState) =>
    fruitFilterStatus,
  (fruitFilterStatus) => Status.INIT === fruitFilterStatus,
);

export const checkIsInitFruitFiltersBlocked = createSelector(
  ({ plpFilterCategories: { fruitFilterStatus } }: RootState) =>
    fruitFilterStatus,
  (fruitFilterStatus) =>
    [Status.INIT, Status.PENDING].includes(fruitFilterStatus),
);

export const getCategories = createSelector(
  ({ plpFilterCategories: { categories } }: RootState) => categories,
  (categories) => categories,
);

export const getOccasions = createSelector(
  ({ plpFilterCategories: { categories } }: RootState) => categories,
  (categories) =>
    categories.filter((cat) =>
      isCanada
        ? cat.ID === FilterType.occasionFilterCa
        : cat.ID === FilterType.occasionFilter,
    ),
);

export const getSpeedCategories = createSelector(
  ({ plpFilterCategories: { categories } }: RootState) => categories,
  (categories) => categories.filter((cat) => cat.Name === 'Speed'),
);

export const getPriceRange = createSelector(
  ({ plpFilterCategories: { categories } }: RootState) => categories,
  (categories) =>
    categories.filter((cat) =>
      isCanada
        ? cat.ID === FilterType.priceFilterCa
        : cat.ID === FilterType.priceFilter,
    ),
);

export const getFilters = createSelector(
  ({ plpFilterCategories: { categories } }: RootState) => categories,
  (categories) =>
    categories
      .map(({ Items }) => Items)
      .reduce((acc, filters) => [...acc, ...filters], []),
);

export const getFruitFilters = createSelector(
  ({ plpFilterCategories: { fruitFilters } }: RootState) => fruitFilters,
  (fruitFilters) => fruitFilters,
);

export const getFiltersWithCategoryName = createSelector(
  ({ plpFilterCategories: { categories } }: RootState) => categories,
  (categories) =>
    categories
      .map((category) =>
        category.Items.map((item) => ({
          ...item,
          categoryName: category.Name,
        })),
      )
      .reduce((acc, filters) => [...acc, ...filters], []),
);
