import { DEFAULT_FILTER } from 'api/filters-api.service';
import {
  FILTER_QUERY_KEY,
  FRUIT_QUERY_KEY,
} from 'containers/PLPContainer/query.constants';
import { Filter, FruitFilter } from 'api/filter-categories/types';
import { isEmpty } from 'utils/array/size';
import { selectFilter } from 'redux/PLP/filter/selectors';
import { useQueryParametersRemover } from 'containers/PLPContainer/hooks/query/use-query-parameters-remover';
import { useQueryParametersSetter } from 'containers/PLPContainer/hooks/query/use-query-parameters-setter';
import { useSelector } from 'react-redux';

export const useSetQueryFilters = (): ((
  filters: Filter[],
  fruitFilters?: FruitFilter[],
) => void) => {
  const setQueryParams = useQueryParametersSetter();
  const removeQueryParam = useQueryParametersRemover();
  const pageFilter = useSelector(selectFilter);

  return (filters: Filter[], fruitFilters: FruitFilter[] = []) => {
    if (isEmpty(filters)) {
      setQueryParams(FILTER_QUERY_KEY, '', FRUIT_QUERY_KEY, [
        ...fruitFilters.map(({ ProductGroupID }) => ProductGroupID.toString()),
      ]);
      return;
    }
    const noRealFiltersExists = !filters.filter(
      (item) => item !== DEFAULT_FILTER && item.Value !== pageFilter?.value,
    ).length;
    if (noRealFiltersExists && isEmpty(fruitFilters)) {
      removeQueryParam([FILTER_QUERY_KEY, FRUIT_QUERY_KEY]);
      return;
    }
    setQueryParams(
      FILTER_QUERY_KEY,
      [...filters.map(({ Value }) => Value)],
      FRUIT_QUERY_KEY,
      [...fruitFilters.map(({ ProductGroupID }) => ProductGroupID.toString())],
    );
  };
};
