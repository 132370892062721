import {
  FLEX_FREE,
  FLEX_NEXT_DAY_PRICE,
} from 'translations/locales/translation-keys.constant';
import { Typography } from '@mui/material';
import { addCurrency } from 'utils/add-currency';
import React, { FC } from 'react';
import i18next from 'i18next';

type Props = {
  fee: number;
  selectedProductPrice?: number;
};

export const NonFlexFee: FC<Props> = ({ fee, selectedProductPrice = 0 }) => {
  const hasFee = fee !== 0;

  const cartTotal = sessionStorage.getItem('CartMerchandiseTotal');

  const feeToDisplay = () => {
    let displayedFee;
    if (hasFee) {
      displayedFee = `+${addCurrency(fee)}*`;
    }
    if (!hasFee) {
      if (
        selectedProductPrice + Number(cartTotal) >
        window.MIN_ORDER_TOTAL_THRESHOLD
      ) {
        displayedFee = i18next.t(FLEX_FREE);
      } else {
        displayedFee = i18next.t(FLEX_NEXT_DAY_PRICE);
      }
    }

    return displayedFee;
  };

  return (
    <Typography variant="subtitle2" color={hasFee ? undefined : 'primary'}>
      {feeToDisplay()}
    </Typography>
  );
};
