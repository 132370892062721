import * as TK from 'components/PDP/Details/components/PasControl/components/ZipAvailability/translations/locale.constants';
import * as TK1 from 'components/Header/Menus/CountryMenu/translations/menus.locale.constants';

import * as style from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.style';
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Area } from 'api/area/types/area.interface';
import { AvailabilityStep } from 'containers/PDPContainer/enums/availability-step.enum';
import {
  CA_ZIP_LENGTH,
  ZIP_LENGTH,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.constants';
import { CHOOSE_RECIPIENT } from 'translations/locales/translation-keys.constant';
import { CountryMenu } from 'components/Header/Menus/CountryMenu';
import { Key } from 'enums/key';
import { RecipientLockAlert } from 'components/Session/Alert/RecipientLockAlert/RecipientLockAlert';
import { StepContent } from 'components/PDP/Details/components/PasControl/components/Stepper/styled';
import { StepSummary } from 'components/PDP/Details/components/PasControl/components/Stepper/StepSummary';
import { analytics } from 'service/adobe-analytics/analytics';
import { appInsights } from 'utils/telemetry/app-insights';
import { getArea } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.actions';
import { isCanada, isUS } from 'utils/country';
import {
  isFullZip,
  isUsZip,
  sanitizeZip,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/zip-availability.utils';
import { resetAvailabilitySession } from 'redux/session/availability/availability.action';
import { segment } from 'service/segment/segment';
import {
  selectAreaData,
  selectIsAreaError,
  selectIsAreaPending,
  selectIsFulfilled,
  selectIsInit,
} from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.selectors';
import { selectAvailability } from 'redux/session/availability/availability.selectors';
import { selectExistsRecipients } from 'components/Session/Alert/RecipientLockAlert/components/MultipleRecipientLock/feature/selectors';
import { selectRecipientsAvailable } from 'components/Session/Alert/feature/selectors';
import {
  selectUser,
  selectUserAccountInformation,
} from 'providers/Session/feature/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { zipAvailability } from 'components/PDP/Details/components/PasControl/components/ZipAvailability/feature/zip-availability.slice';
import React, {
  ChangeEventHandler,
  FC,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';
import i18next from 'i18next';

interface Props {
  hideAlert: () => void;
  defaultValue?: string;
  onSubmit: (area: Area | null) => void;
  setActiveStepperStep: (d: number) => void;
  isStepClickable: boolean;
  zipRef?: MutableRefObject<HTMLInputElement | null>;
  zipCodeFromPickupForm?: string;
}

export const ZipAvailability: FC<Props> = ({
  hideAlert,
  defaultValue,
  onSubmit,
  setActiveStepperStep,
  isStepClickable,
  zipRef,
  zipCodeFromPickupForm,
}) => {
  const dispatch = useDispatch();

  const recipientAvailability = useSelector(selectRecipientsAvailable);
  const area = useSelector(selectAreaData);
  const isLoading = useSelector(selectIsAreaPending);
  const isInit = useSelector(selectIsInit);
  const isError = useSelector(selectIsAreaError);
  const isFulfilled = useSelector(selectIsFulfilled);
  const recipientExist = useSelector(selectExistsRecipients);
  const availabilitySession = useSelector(selectAvailability);
  const userSession = useSelector(selectUser);
  const userAccountInformation = useSelector(selectUserAccountInformation);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [invalidCA, setInvalidCA] = useState<boolean>(false);
  const [zip, setZip] = useState(defaultValue ?? '');
  const [preview, setPreview] = useState<string | undefined>(
    isInit ? undefined : defaultValue,
  );

  const showRecipientLock = recipientAvailability && recipientExist;

  useEffect(() => {
    if (isInit) {
      setPreview('');
    }
  }, [isInit]);

  useEffect(() => {
    if (isFulfilled && area) {
      setPreview(area.name);
      onSubmit(area);
      appInsights.trackEvent({
        name: 'PAS area selected',
        properties: { area: area.name },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFulfilled]);

  useEffect(() => {
    if (!defaultValue) {
      setPreview('');
      setZip('');
    }
  }, [defaultValue]);

  // HERE
  useEffect(() => {
    if (isError) {
      appInsights.trackEvent({ name: 'Area not serviced' }, { zipCode: zip });
      segment.areaNotServicedEvent(zip, userSession, userAccountInformation);
      setAlertOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const handleZip = (zipValue: string) => {
    const value = sanitizeZip(zipValue);

    setZip(value);
    if (isUsZip(value) && isCanada) {
      setInvalidCA(true);
    }
    if (isFullZip(value)) {
      dispatch(getArea(value));
      setInvalid(false);
      setAlertOpen(false);
      setInvalidCA(false);
      return;
    }

    onSubmit(null);

    if (availabilitySession.isSet) {
      dispatch(resetAvailabilitySession());
    }

    setAlertOpen(false);
    dispatch(zipAvailability.reset());
  };

  const onChangeZip: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    hideAlert();
    setInvalid(false);
    handleZip(target.value);
  };

  const validateZip = (inputValue: string) => {
    handleZip(inputValue);

    if (inputValue.length < ZIP_LENGTH) {
      setInvalid(true);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    setAlertOpen(false);
    dispatch(zipAvailability.reset());

    if (e.key === Key.Enter) {
      validateZip((e.target as HTMLInputElement).value);
    }
  };

  const handleTextFieldFocus = () => {
    analytics.zipTextFieldFocus();
  };

  useEffect(() => {
    if (zip && isInit) {
      handleZip(zip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (zipCodeFromPickupForm) {
      handleZip(zipCodeFromPickupForm);
    }
  }, [zipCodeFromPickupForm]);

  return (
    <>
      <StepSummary
        availabilityStep={AvailabilityStep.ZIP_AVAILABILITY}
        setActiveStep={setActiveStepperStep}
        clickable={isStepClickable}
        preview={preview}
      >
        {i18next.t(TK.WHERE_IS_IT_GOING)}
      </StepSummary>
      <StepContent>
        {isCanada && (
          <Box sx={style.countryBox}>
            <Typography
              variant="subtitle2"
              style={{ width: '200px' }}
              mb={[2.5, 0, 0]}
              mt={[0, 1, 1]}
            >
              {' '}
              {i18next.t(TK1.COUNTRY_TITLE)}
            </Typography>
            <CountryMenu />
          </Box>
        )}
        <Box sx={style.wrapper}>
          <TextField
            onKeyDown={onKeyDown}
            onChange={onChangeZip}
            onFocus={handleTextFieldFocus}
            onBlur={(event) => validateZip(event.target.value)}
            fullWidth
            variant="outlined"
            label={i18next.t(isUS ? TK.ZIP_LABEL : TK.POSTAL_CODE_LABEL)}
            value={zip.toUpperCase()}
            inputRef={zipRef}
            sx={{
              width: showRecipientLock ? ['90%', '50%', '50%', '50%'] : '100%',
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                pr: 0,
              },
            }}
            InputProps={{
              inputProps: {
                maxLength: isCanada ? CA_ZIP_LENGTH : ZIP_LENGTH,
                inputMode: isCanada ? 'text' : 'numeric',
                'data-test': 'pdp-pas-zip-input',
              },
              endAdornment: isLoading && (
                <InputAdornment position="end" sx={style.adornment}>
                  <CircularProgress color="secondary" size={24} />
                </InputAdornment>
              ),
            }}
            disabled={isLoading}
            error={invalid}
            helperText={
              invalid ? (
                <Typography sx={style.errorText} data-test="pdp-pas-zip-error">
                  {i18next.t(
                    isUS ? TK.ZIP_VALIDATE_ERROR : TK.POSTAL_CODE_ERROR,
                  )}
                </Typography>
              ) : (
                ''
              )
            }
          />
          <Box sx={style.flexBox}>
            <Box sx={style.dividerLine} />
            <Typography
              variant="body2"
              display={showRecipientLock ? 'inline' : 'none'}
              sx={style.dividerText}
            >
              or
            </Typography>
            <Box sx={style.dividerLine} />
          </Box>
          <Box
            sx={style.recipientLock}
            display={showRecipientLock ? 'inline' : 'none'}
          >
            <RecipientLockAlert
              displayRecipientName={showRecipientLock}
              multipleRecipients={showRecipientLock}
              shortDate={showRecipientLock}
              recipientLockTitle={i18next.t(CHOOSE_RECIPIENT)}
            />
          </Box>
        </Box>
      </StepContent>
      <Collapse in={alertOpen && !invalidCA}>
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          sx={style.alertText}
          severity="warning"
        >
          <Typography data-test="pdp-pas-zip-error-alert">
            {i18next.t(TK.ZIP_SERVICE_WARNING, { zipCode: zip })}
          </Typography>{' '}
        </Alert>
      </Collapse>
      <Collapse in={invalidCA}>
        <Alert sx={style.alertText} severity="error">
          <Box sx={{ display: ['block', 'flex', 'flex'] }}>
            <Box>
              <Typography
                data-test="pdp-pas-zip-error"
                variant="subtitle1"
                fontWeight="500"
                fontSize="16px"
              >
                {i18next.t(TK.ZIP_USA_ERROR)}
              </Typography>
              <Typography data-test="pdp-pas-zip-error-sub" variant="body2">
                {i18next.t(TK.ZIP_USA_ERROR_SUB)}
              </Typography>
            </Box>
            <Link
              href="https://www.ediblearrangements.com"
              target="_blank"
              sx={{
                marginTop: '15px',
                paddingLeft: ['0', '200px', '120px'],
                fontWeight: '600',
                fontSize: '13px',
                color: '#5F2120',
                borderColor: '#000000DE',
              }}
            >
              {i18next.t(TK.SHOP_EDIBLE_US)}
            </Link>
          </Box>
        </Alert>
      </Collapse>
    </>
  );
};
