import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/PaymentInfo/styles';
import {
  AmericanLogo,
  DiscoveryLogo,
  MasterLogo,
  VisaLogo,
} from 'utils/cloudinaryAssets';
import { Box, Typography } from '@mui/material';
import { CreditCardType, Sale } from 'redux/order-confirmation/types/types';
import { Image } from 'components/common/Image/Image';
import { formatToExpiryDate } from 'utils/date';
import React, { FC } from 'react';
import i18next from 'i18next';

interface Props {
  saleData: Sale;
}

export const CreditCardTypeInfo: FC<Props> = ({ saleData }) => {
  const type = saleData && saleData.creditCardType;
  return (
    <>
      <Box sx={styles.cardContent}>
        <p>Credit Card:</p>
        {type === CreditCardType.MASTER && (
          <Image
            alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_CREDIT_CARD_IMAGE)}
            width={40}
            height={30}
            className="masterImg"
            src={MasterLogo}
          />
        )}
        {type === CreditCardType.VISA && (
          <Image
            alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_CREDIT_CARD_IMAGE)}
            width={39}
            height={13}
            className="visaImg"
            src={VisaLogo}
          />
        )}
        {type === CreditCardType.AMEX && (
          <Image
            alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_CREDIT_CARD_IMAGE)}
            width={32}
            height={30}
            className="americanImg"
            src={AmericanLogo}
          />
        )}
        {type === CreditCardType.DISCOVER && (
          <Image
            alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_CREDIT_CARD_IMAGE)}
            width={90}
            height={30}
            className="discoverImg"
            src={DiscoveryLogo}
          />
        )}
      </Box>
      <Typography sx={styles.cardContent}>
        {`**** **** **** ${
          saleData.creditCardNumber !== null
            ? saleData.creditCardNumber
            : '****'
        }`}
      </Typography>
      <Box sx={styles.cardContent}>
        <p>Expiry Date: </p>
        <Typography sx={styles.marginLeft}>
          {formatToExpiryDate(saleData.creditCardExpirationDate)}
        </Typography>
      </Box>
    </>
  );
};
