import * as TK from 'translations/locales/translation-keys.constant';
import * as styles from 'components/OrderConfirmation/CreateAccount/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import { HideUpMd } from 'utils/hidden/hide-up-md';
import { Image } from 'components/common/Image/Image';
import { ShowUpMd } from 'utils/hidden/show-up-md';
import { ShowUpSm } from 'utils/hidden/show-up-sm';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import {
  StrawberryImg,
  StrawberryImgMob,
  StrawberryImgTab,
} from 'utils/cloudinaryAssets';
import React, { FC } from 'react';
import i18next from 'i18next';

export const AccountBannerSignUp: FC = () => {
  return (
    <Box sx={styles.accountPromo}>
      <Box sx={styles.rewardContainer}>
        <Typography sx={styles.rewardHead}>
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_THANKS_MSG)}
        </Typography>
        <ShowUpMd>
          <Image
            alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_STRAWBERRY)}
            className="strawberryImg1"
            src={StrawberryImg}
          />
        </ShowUpMd>
        <HideUpMd>
          <ShowUpSm>
            <Image
              alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_STRAWBERRY)}
              className="strawberryImg1"
              src={StrawberryImgTab}
            />
          </ShowUpSm>
          <ShowUpXs>
            <Image
              alt={i18next.t(TK.ORDER_CONFIRMATION_PAGE_STRAWBERRY)}
              className="strawberryImg1"
              src={StrawberryImgMob}
            />
          </ShowUpXs>
        </HideUpMd>
        <Box sx={styles.listText} className="rewardText">
          <p>{i18next.t(TK.ORDER_CONFIRMATION_PAGE_LIST_TEXT1)}</p>
          <p>{i18next.t(TK.ORDER_CONFIRMATION_PAGE_LIST_TEXT2)}</p>
        </Box>
        <Button
          variant="text"
          type="button"
          size="small"
          data-test="view-rewards-button"
          aria-label="view-rewards"
          className="rewardsBtn"
          sx={styles.redBtnStyle}
          href="/my-account/"
        >
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_VIEW_REWARDS)}
        </Button>
        <Link
          href="/terms-and-conditions/"
          className="termsStyle"
          sx={styles.linkStyle}
        >
          {i18next.t(TK.ORDER_CONFIRMATION_PAGE_TERMS_TEXT)}
        </Link>
      </Box>
    </Box>
  );
};
